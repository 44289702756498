<script>
import useVuelidate from "@vuelidate/core";
import { required, helpers } from "@vuelidate/validators";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import Swal from "sweetalert2";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      page: 1,
      perPage: 5,
      pages: [],
      slice: [],
      submited: false,
      spanView: false,
      slices: {},
      data: {},
      title: "Gestion ecolages",
      items: [
        {
          text: "Ecolages",
          href: "/",
        },
        {
          text: "Gerer",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      classe_id: {
        required: helpers.withMessage("Veuillez choisir la classe", required),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
  },
  mounted() {
    this.getSlices();
  },

  methods: {
    initSliceCreation() {
      this.submited = true;
      this.createSlice();
    },

    createSlice() {
      this.spanView = true;
      this.data.annee_scolaire = localStorage.getItem("selectedYear");
      const self = this;
      this.v$.data.$touch();
      this.$store
        .dispatch("postRequest", { route: "api/slice ", data: this.data })
        .then(
          function (response) {
            self.data = {};
            self.getSlices();
            self.spanView = false;
            Swal.fire(
              response.data.message,
              "Tranche créée avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "La somme des pourcentages des tranches ne doit pas excéder 100%",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    initSliceUpdate() {
      this.submited = true;
      this.updateSlice();
    },

    updateSlice() {
      this.spanView = true;
      const self = this;
      this.$store
        .dispatch("putRequest", {
          route: `api/slice/${this.slice.id}`,
          data: this.slice,
        })
        .then(
          function (response) {
            self.spanView = false;
            self.getSlices();
            Swal.fire(
              response.data.message,
              "Modification éffectuer avec succès!",
              "success"
            );
          },
          function (error) {
            if (error !== null) {
              self.spanView = false;
              Swal.fire({
                title: "Oops...",
                text: "Il y a un problème!",
                icon: "error",
                confirmButtonClass: "btn btn-primary w-xs mt-2",
                buttonsStyling: false,
                showCloseButton: true,
              });
            }
          }
        );
    },

    getSlices() {
      const self = this;
      this.data.school_year = localStorage.getItem("selectedYear");
      this.data.classe_id = this.$route.params.classeId;
      this.$store
        .dispatch("getRequest", {
          route: `api/slice/${this.data.classe_id}/${this.data.school_year}`,
          data: this.data,
        })
        .then(
          function (response) {
            self.slices = response.data;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },

    async deleteSlice (id) {
      const self = this;
      Swal.fire({
        title: 'Etes vous sûr ?',
        text: 'voulez-Vous supprimer cette tranche? Cette action est irréversible!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonClass: 'btn btn-primary w-xs mt-2',
        cancelButtonClass: 'btn btn-danger w-xs mt-2',
        confirmButtonText: 'supprimer!'
      }).then(async result => {
        if (result.value == 1) {
          this.$store.dispatch('deleteRequest', {
              route: `api/slice/${id}`,
              data: this.data
            })
            .then(
              function (response) {
                self.getSlices();
                console.log(response);
                Swal.fire({
                  title: 'Supprimer',
                  text: 'Faculté supprimée avec succès!',
                  icon: 'success'
                })
              },
              function (error) {
                Swal.fire({
                  title: 'Oops...',
                  text: error,
                  icon: 'error',
                  confirmButtonClass: 'btn btn-primary w-xs mt-2',
                  buttonsStyling: false,
                  showCloseButton: true
                })
              }
            )
        }
      })
    },


    deleteFaculty(id) {
      const self = this;
      Swal.fire({
        title: "Etes vous sûr ?",
        text: "voulez-Vous supprimer cette alerte? Cette action est irréversible!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonClass: "btn btn-primary w-xs mt-2",
        cancelButtonClass: "btn btn-danger w-xs mt-2",
        confirmButtonText: "supprimer!",
      }).then(async (result) => {
        if (result.value) {
          this.$store
            .dispatch("postRequest", {
              route: "api/departments ",
              data: { idenfiant: id },
            })
            .then(
              function (response) {
                self.fileres = response.data.filieres;
                Swal.fire({
                  title: "Supprimer",
                  text: "Faculté supprimée avec succès!",
                  icon: "success",
                });
              },
              function (error) {
                Swal.fire({
                  title: "Oops...",
                  text: error,
                  icon: "error",
                  confirmButtonClass: "btn btn-primary w-xs mt-2",
                  buttonsStyling: false,
                  showCloseButton: true,
                });
              }
            );
        }
      });
    },

    getSlice(tranche) {
      this.slice = tranche;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.fileres.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
  },
};
</script>
<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-between mb-3">
            <div class="col-md-4 mb-3">
              <input type="text" class="form-control" placeholder="Search..." />
            </div>
            <div class="col-md-6 mb-3 d-flex justify-content-end">
              <button
                type="button"
                class="btn btn-primary me-3"
                data-bs-toggle="modal"
                data-bs-target=".bs-example-modal-lg"
              >
                <i class="mdi mdi-plus"></i>
                Tranche
              </button>
            </div>
          </div>

          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" scope="col" data-sort="currency_name">Id</th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Classe
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Année scholaire
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Pourcentage
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Montant
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Date d'échéance
                  </th>

                  <th class="sort" scope="col" data-sort="currency_name">
                    Action
                  </th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(slice, index) in slices" :key="index">
                  <th scope="row">#{{ index+1 }}</th>
                  <th scope="row">{{ slice.classe.name }}</th>

                  <td>{{ slice.school_year }}</td>
                  <td>{{ slice.rate }} %</td>
                  <td>
                    {{
                      (parseInt(slice.rate) / 100) *
                      parseInt(slice.classe.ecolage)
                    }}
                    Fcfa
                  </td>
                  <td>{{ slice.date_echeance }}</td>

                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        data-bs-toggle="modal"
                        data-bs-target=".bs-faculty-update"
                        @click="getSlice(slice)"
                        class="link-success fs-15"
                        ><i class="ri-pencil-line"></i
                      ></a>
                      <a
                        href="javascript:void(0);"
                        @click="deleteSlice(slice.id)"
                        class="link-danger fs-15"
                        ><i class="ri-delete-bin-line"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
        <div
          class="modal fade bs-example-modal-lg"
          tabindex="-1"
          role="dialog"
          aria-labelledby="myLargeModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initSliceCreation">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Nouvelle Tranche
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Année scholaire</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="annee"
                          placeholder="Groupe ou Série"
                          readonly
                          v-model="data.school_year"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Pourcentage</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ecolage"
                          placeholder="Pourcentage"
                          v-model="data.rate"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Date d'échéance</label
                        >
                        <flat-pickr
                          class="form-control"
                          v-model="data.date_echeance"
                          placeholder="Date d'échéance"
                        />
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Ajouter
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
        <div
          class="modal fade bs-faculty-update"
          tabindex="-1"
          role="dialog"
          aria-labelledby="updateFacultyModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <form @submit.prevent="initSliceUpdate">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="updateFacultyModal">Modifier</h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body">
                  <div class="row">
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Année scholaire</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="annee"
                          placeholder="Groupe ou Série"
                          readonly
                          v-model="slice.school_year"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-6">
                      <div class="mb-3">
                        <label for="ecolage" class="form-label"
                          >Pourcentage</label
                        >
                        <input
                          type="text"
                          class="form-control"
                          id="ecolage"
                          placeholder="Groupe ou Série"
                          v-model="slice.rate"
                        />
                      </div>
                    </div>
                    <!--end col-->
                    <div class="col-lg-12">
                      <div class="mb-3">
                        <label for="formationInput" class="form-label"
                          >Date d'échéance</label
                        >
                        <flat-pickr
                          class="form-control"
                          v-model="slice.date_echeance"
                          placeholder="Date d'échéance"
                        />
                      </div>
                    </div>
                    <!--end col-->
                  </div>
                  <!--end row-->
                </div>
                <div class="modal-footer">
                  <a
                    href="javascript:void(0);"
                    class="btn btn-link link-success fw-medium"
                    data-bs-dismiss="modal"
                  >
                    <i class="ri-close-line me-1 align-middle"></i> Close
                  </a>
                  <button
                    type="submit"
                    class="btn btn-primary"
                    :disabled="spanView"
                  >
                    <span
                      v-if="spanView"
                      class="spinner-border spinner-border-sm"
                    ></span>
                    Confirmer
                  </button>
                </div>
              </div>
            </form>
            <!-- /.modal-content -->
          </div>
          <!-- /.modal-dialog -->
        </div>
      </div>
    </div>
  </Layout>
</template>
